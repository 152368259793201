import debounce from 'lodash.debounce'

import { CancelablePromise, apiRequest } from '../../../main/services/api'
import Treemap from '../treemap'
import { MapData, MapDataNode, MapTypeId, PerfData, Scale } from '../types'
import { getVisibleTooltipNodes } from '../utils'
import AppDispatcher from './appDispatcher'
import { ActionTypes } from './constants'
import mapStore from './mapStore'

var _targetScale = 1
function zoomIn(treemap: Treemap, scale: number, tx: number, ty: number) {
  _targetScale = scale
  animate()
  function animate() {
    treemap.zoom.scale(+(treemap.zoom.scale() + 0.05).toFixed(2), { x: tx, y: ty })
    AppDispatcher.handleViewAction({ type: ActionTypes.RENDER_TREEMAP })
    if (treemap.zoom.scale() + 0.05 < _targetScale + 0.00001) {
      requestAnimationFrame(animate)
    }
  }
}

function zoomOut(treemap: Treemap, scale: number, tx: number, ty: number) {
  _targetScale = scale
  animate()
  function animate() {
    treemap.zoom.scale(+(treemap.zoom.scale() - 0.05).toFixed(2), { x: tx, y: ty })
    AppDispatcher.handleViewAction({ type: ActionTypes.RENDER_TREEMAP })
    if (treemap.zoom.scale() - 0.05 > _targetScale - 0.00001) {
      requestAnimationFrame(animate)
    }
  }
}

function updateVisibleSparklines(treemap: Treemap) {
  const hoveredNode = mapStore.getHoveredNode(treemap.mapNodeId)
  const visibleNodes = getVisibleTooltipNodes(hoveredNode)

  if (!visibleNodes.length) return

  const tickersToLoad = visibleNodes.map((node) => node!.name)
  if (hoveredNode && !tickersToLoad.includes(hoveredNode?.name)) {
    tickersToLoad.push(hoveredNode.name)
  }

  debouncedLoadSparklines(tickersToLoad, treemap.type)
}

let _lastSparklinesRequest: CancelablePromise<any> | null = null
async function loadSparklines(tickers: string[], mapType: string) {
  AppDispatcher.handleViewAction({
    type: ActionTypes.LOAD_SPARKLINES_STARTED,
  })
  _lastSparklinesRequest?.cancel?.()
  _lastSparklinesRequest = apiRequest(
    '/api/map_sparklines.ashx',
    { query: { t: tickers.join(','), ty: mapType } },
    true
  )

  try {
    const data = await _lastSparklinesRequest
    AppDispatcher.handleServerAction({
      type: ActionTypes.LOAD_SPARKLINES_COMPLETED,
      data: data,
    })
  } catch (error) {
    // This just means we canceled the previous request
    if (_lastSparklinesRequest.aborted) return

    AppDispatcher.handleServerAction({
      type: ActionTypes.LOAD_SPARKLINES_FAILED,
      error,
    })
  }
}

const debouncedLoadSparklines = debounce(loadSparklines, 200, { trailing: true })

const actions = {
  setHoveredNode(mapNodeId: string, node: MapDataNode | undefined) {
    AppDispatcher.handleViewAction({
      type: ActionTypes.SET_HOVERED_NODE,
      node,
      mapNodeId,
    })
  },

  loadSparklineData(tickers: string[], mapType: MapTypeId) {
    if (tickers.length > 0) {
      debouncedLoadSparklines(tickers, mapType)
    }
  },

  loadSparklinesCancel() {
    _lastSparklinesRequest?.cancel?.()
  },

  resetSparklineData() {
    AppDispatcher.handleViewAction({
      type: ActionTypes.RESET_SPARKLINE_DATA,
    })
  },

  changeTranslate(treemap: Treemap, tx: number, ty: number) {
    treemap.zoom.translate([tx, ty])
    AppDispatcher.handleViewAction({
      type: ActionTypes.CHANGE_TRANSLATE,
    })
  },

  zoom(treemap: Treemap, direction: number, offsetX: number, offsetY: number) {
    if (direction > 0) {
      const zoomLevel = treemap.getNextZoomLevel()
      if (zoomLevel !== treemap.zoom.scale()) {
        zoomIn(treemap, zoomLevel, offsetX, offsetY)
      }
    } else {
      const zoomLevel = treemap.getPreviousZoomLevel()
      if (zoomLevel !== treemap.zoom.scale()) {
        zoomOut(treemap, zoomLevel, offsetX, offsetY)
      }
    }
  },

  render() {
    AppDispatcher.handleViewAction({ type: ActionTypes.RENDER_TREEMAP })
  },

  zoomAndTranslate(treemap: Treemap, scale: number, tx: number, ty: number) {
    treemap.zoom.scale(1, { x: tx, y: ty })
    treemap.zoom.scale(scale, { x: tx, y: ty })
    AppDispatcher.handleViewAction({ type: ActionTypes.RENDER_TREEMAP })
  },

  updateLayout(
    treemap: Treemap,
    props: { width: number; height: number; data: MapData; scale: Scale; dataHash: string }
  ) {
    treemap.updateData(props)
    treemap.zoom.updateDimensions(props)
    AppDispatcher.handleViewAction({ type: ActionTypes.RENDER_TREEMAP })

    // Update hovered node if any
    const hoveredNode = mapStore.getHoveredNode(treemap.mapNodeId)
    if (hoveredNode) {
      AppDispatcher.handleViewAction({
        type: ActionTypes.SET_HOVERED_NODE,
        node: treemap.nodes.find((node) => node.name === hoveredNode.name),
        mapNodeId: treemap.mapNodeId,
      })
      updateVisibleSparklines(treemap)
    }
  },

  updatePerfData(treemap?: Treemap, data?: PerfData) {
    if (!treemap || !data) return

    treemap.updatePerf(data)
    AppDispatcher.handleServerAction({ type: ActionTypes.UPDATE_DATA, version: data.version })

    updateVisibleSparklines(treemap)
  },

  _setOnPublish(getPublishCanvas: () => HTMLCanvasElement) {
    AppDispatcher.handleViewAction({
      type: ActionTypes.SET_PUBLISH_CANVAS,
      getPublishCanvas,
    })
  },

  setupWidget() {
    AppDispatcher.handleViewAction({
      type: ActionTypes.SET_WIDGET,
      isWidget: true,
    })
  },
}

export default actions
