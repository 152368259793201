import classNames from 'classnames'
import React from 'react'

enum TagSize {
  small = 'h-4 min-w-4', // 16px height
  medium = 'h-5 min-w-5', // 20px height
}
type TagSizeType = keyof typeof TagSize

enum TagPadding {
  small = 'px-[5px] py-0', // 16px height
  medium = 'px-1.5 py-0.5', // 20px height
}
type TagPaddingType = keyof typeof TagPadding

enum TagFont {
  small = 'text-3xs leading-3', // 10px/12px
  medium = 'text-2xs leading-4', // 12px/16px
}

type TagProps = {
  size?: TagSizeType
  padding?: TagPaddingType
}

export function Tag({ size = 'medium', padding = size, children }: React.PropsWithChildren<TagProps>) {
  const tagPadding = TagPadding[padding]
  const tagSize = TagSize[size]
  const tagFont = TagFont[size]

  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center rounded-sm border border-bg-primary bg-gray-50 text-gray-600 font-medium dark:bg-gray-600 dark:text-gray-50',
        tagPadding,
        tagSize,
        tagFont
      )}
    >
      {children}
    </span>
  )
}
