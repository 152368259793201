import { Component, MouseEvent } from 'react'
import ReactDOM from 'react-dom/client'

import { CancelablePromise, apiRequest } from '../../main/services/api'
import { Instrument } from '../../main/types'
import {
  ChartElementType,
  ChartLayout,
  LayoutType,
  Quote,
  RootChartConfigObject,
  ScaleType,
  TIMEFRAME,
  Theme,
} from '../charts-lib'

const ticker = 'market_sentiment'

const MARKET_SENTIMENT_WIDTH_PX = 160
const MARKET_SENTIMENT_HEIGHT_PX = 40
const marketSentimentChartConfig: RootChartConfigObject = {
  layout: LayoutType['1h1v'],
  theme: FinvizSettings.hasDarkTheme ? Theme.dark : Theme.light,
  width: MARKET_SENTIMENT_WIDTH_PX,
  height: MARKET_SENTIMENT_HEIGHT_PX,
  scrollable: false,
  editable: false,
  cross: false,
  charts: [
    {
      width: MARKET_SENTIMENT_WIDTH_PX,
      height: MARKET_SENTIMENT_HEIGHT_PX,
      timeframe: TIMEFRAME.i3,
      scale: ScaleType.Linear,
      ticker: ticker,
      instrument: Instrument.MarketSentiment,
      refreshData: false,
      premarket: false,
      aftermarket: false,
      dateRange: null,
      stretch: true,
      panes: [
        {
          height: MARKET_SENTIMENT_HEIGHT_PX,
          elements: [
            {
              type: ChartElementType.MarketSentiment,
              overlays: [],
            },
          ],
        },
      ],
    },
  ],
}

interface MarketSentimentState {
  loading: boolean
  enabled: boolean
  chartConfig: RootChartConfigObject | null
}

class MarketSentiment extends Component {
  state: MarketSentimentState = {
    loading: true,
    enabled: false,
    chartConfig: null,
  }

  _timer: number | null = null
  _lastRequest: CancelablePromise<any> | null = null

  componentDidMount() {
    this._fetchData('', () => {
      this.setState({ chartConfig: marketSentimentChartConfig })
    })
  }

  componentWillUnmount() {
    if (this._timer !== null) {
      this._lastRequest?.cancel?.()
      clearTimeout(this._timer)
    }
  }

  render() {
    const { loading, enabled, chartConfig } = this.state

    return (
      <table
        width="221"
        cellPadding="0"
        cellSpacing="0"
        // @ts-ignore
        border="0"
        style={{ visibility: loading ? 'hidden' : 'visible' }}
      >
        <tbody>
          <tr>
            <td data-boxover="cssbody=[tooltip_bdy] cssheader=[tooltip_hdr] header=[Where will S&P 500 be in the next 5 minutes?] body=[<table width=300><tr><td class='tooltip_tab'>Click <b>BULL</b> for positive market sentiment<br>Click <b>BEAR</b> for negative market sentiment</td></tr></table>] offsetx=[-200] offsety=[20] delay=[100]">
              {chartConfig && (
                <div id="js-market-sentiment-chart" style={{ width: 160, height: 40 }}>
                  <ChartLayout config={chartConfig} />
                </div>
              )}
            </td>
            <td valign="top">
              <button
                id="msb_bull"
                className="market-sentiment_bull"
                disabled={!enabled}
                onClick={(e) => this._vote(e, 'bull')}
              >
                Bull
              </button>
              <img
                src="gfx/home/msb_separator.gif"
                width="61"
                height="1"
                alt=""
                // @ts-ignore
                border="0"
                style={{ display: FinvizSettings.hasRedesignEnabled ? 'none' : 'block' }}
              />
              <button
                id="msb_bear"
                className="market-sentiment_bear"
                disabled={!enabled}
                onClick={(e) => this._vote(e, 'bear')}
              >
                Bear
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  _vote(e: MouseEvent, sentiment: string) {
    e.preventDefault()
    this.setState({ enabled: false })
    this._fetchData('?b=' + sentiment, undefined, false)
  }

  _fetchData = async (urlQuery = '', callback?: () => void, setTimer = true) => {
    if (setTimer && this._timer !== null) clearTimeout(this._timer)

    if (document.hidden) {
      // console.log(`${+new Date()} Document is hidden, canceling refresh`)
      if (setTimer) this._timer = window.setTimeout(() => this._fetchData(urlQuery, callback), 1000)
      return
    }

    // console.log(`${+new Date()} Document is visible, refreshing`)

    this._lastRequest = apiRequest<QuoteData>(`/api/market_sentiment.ashx${urlQuery}`)
    const json = await this._lastRequest
    if (json) {
      const quoteData = getData(json)
      const quote = Quote.findByAttribute('ticker', ticker)
      if (quote) {
        Quote.findByAttribute('ticker', ticker)!.updateAttributes(getData(json))
      } else {
        Quote.create(quoteData).save()
      }
      callback?.()

      this.setState({
        loading: false,
        enabled: json.enabled,
      })
    }

    if (setTimer) {
      this._timer = window.setTimeout(() => this._fetchData(urlQuery, callback), (json?.refresh ?? 30) * 1000)
    }
  }
}

interface QuoteData {
  refresh: number
  enabled: boolean
  sum: number[]
  weight: number[]
  time: string
  visibleMinutes: number
}

function getData(data: QuoteData) {
  return {
    close: data.sum,
    volume: data.weight,
    date: Array.from('1'.repeat(data.sum.length)).map((x) => parseInt(x, 10)),
    ticker: ticker,
    timeframe: 'i3',
    instrument: 'market_sentiment',
    premarket: false,
    aftermarket: false,
    afterChange: null,
    afterClose: null,
    drawMinutesPerDay: data.visibleMinutes,
    marketStartMinutes: 570,
    premarketLengthMinutes: 0,
    aftermarketLengthMinutes: 0,
  }
}

let root: ReactDOM.Root
function mount() {
  const rootEl = document.getElementById('js-market-sentiment')
  root = ReactDOM.createRoot(rootEl!)
  root.render(<MarketSentiment />)
}

function unmount() {
  root.unmount?.()
}

mount()

export { mount, unmount }
