import * as React from 'react'

import { PropsWithAs, RequireByKey } from '../types'

const defaultValueAccessor: ValueAccessor<any, any, any> = (data) => data

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_VALUE_KEY = 'value'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_CHANGE_KEY = 'onChange'

type ValueAccessor<
  TagType extends React.ElementType,
  ValueKey extends keyof React.ComponentProps<TagType> = typeof DEFAULT_VALUE_KEY,
  ChangeKey extends keyof React.ComponentProps<TagType> = typeof DEFAULT_CHANGE_KEY,
> = (...args: Parameters<React.ComponentProps<TagType>[ChangeKey]>) => React.ComponentProps<TagType>[ValueKey]

export type InnerStateProps<
  TagType extends React.ElementType,
  ValueKey extends keyof React.ComponentProps<TagType>,
  ChangeKey extends keyof React.ComponentProps<TagType>,
> = RequireByKey<PropsWithAs<TagType>, 'as'> & {
  valueKey?: ValueKey
  changeKey?: ChangeKey
  valueAccessor?: ValueAccessor<TagType, ValueKey, ChangeKey>
}

export function InnerState<
  TagType extends React.ElementType,
  ValueKey extends keyof React.ComponentProps<TagType> = typeof DEFAULT_VALUE_KEY,
  ChangeKey extends keyof React.ComponentProps<TagType> = typeof DEFAULT_CHANGE_KEY,
>({
  as: asComponent,
  valueKey = 'value' as ValueKey,
  changeKey = 'onChange' as ChangeKey,
  valueAccessor = defaultValueAccessor,
  ...props
}: InnerStateProps<TagType, ValueKey, ChangeKey>) {
  const { [valueKey]: value, [changeKey]: onChange } = props
  const [state, setState] = React.useState<React.ComponentProps<TagType>[ValueKey]>(value)

  const handleChange = React.useCallback<ValueAccessor<TagType, ValueKey, ChangeKey>>(
    (...args) => {
      setState(valueAccessor(...args))
      onChange(...args)
    },
    [onChange, valueAccessor]
  )

  return React.createElement(asComponent, {
    ...props,
    [valueKey]: state,
    [changeKey]: handleChange,
  })
}
